var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ticketPopup" }, [
    _c("div", { staticClass: "ticketPopup-box" }, [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "text" }, [_vm._v("结算详情")]),
        _c("div", {
          staticClass: "close",
          on: {
            click: function ($event) {
              return _vm.closeFun()
            },
          },
        }),
      ]),
      _c(
        "div",
        { ref: "content", staticClass: "content", attrs: { id: "content" } },
        [
          _c("h1", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.rowDataDetail.businessType === 1
                    ? "城际专车确认单"
                    : _vm.rowDataDetail.businessType === 2
                    ? "汽车票确认订单"
                    : _vm.rowDataDetail.businessType === 4
                    ? "包车确认单"
                    : _vm.rowDataDetail.businessType === 5
                    ? "租车确认单"
                    : _vm.rowDataDetail.businessType === 6
                    ? "定制班线确认单"
                    : ""
                ) +
                " "
            ),
          ]),
          _c("div", { staticClass: "canvas-mask" }),
          _vm.show
            ? _c("canvas", {
                ref: "myCanvas",
                staticClass: "canvas",
                attrs: { id: "myCanvas", width: "130px", height: "120px" },
              })
            : _vm._e(),
          _c("Table", {
            attrs: {
              "table-data": _vm.tableData,
              resizable: false,
              "title-name": _vm.titleName,
            },
          }),
          _c("div", { staticClass: "table-repair" }, [
            _c("div", { staticClass: "table-repair-remarks" }, [
              _vm._v("备注："),
            ]),
            _c("div", { staticClass: "table-repair-agent" }, [
              _c("div", { staticClass: "agent-left" }, [
                _c("div", { staticClass: "top" }, [
                  _vm._v("经办人：" + _vm._s(_vm.agent)),
                ]),
                _c("div", { staticClass: "bottom" }, [
                  _vm._v("单位：" + _vm._s(_vm.company)),
                ]),
              ]),
              _c("div", { staticClass: "agent-right" }, [
                _c("div", { staticClass: "top" }, [
                  _vm._v("经办人：" + _vm._s(_vm.agent2)),
                ]),
                _vm.show
                  ? _c("div", { staticClass: "bottom" }, [
                      _vm._v(" 单位： 四川神马出行网络科技有限责任公司 "),
                    ])
                  : _c("div", { staticClass: "bottom" }, [
                      _vm._v(
                        " 单位： " +
                          _vm._s(
                            _vm.channelNo == "KK_XCX_KKX"
                              ? "四川英海瑞特科技有限公司"
                              : "沐川县巴适到家汽车运输有限公司"
                          ) +
                          " "
                      ),
                    ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.closeFun()
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c("el-button", { on: { click: _vm.downloadPdf } }, [_vm._v("保存")]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }