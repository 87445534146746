<template>
  <!-- 售票结算 -->
  <div ref="ticketSettlement" class="ticketSettlement">
    <div ref="explain" class="explain">
      <header><i class="el-icon-warning"></i> 数据说明</header>
      <ol>
        <li>【定制客运】应收结算金额=预定金额-退订金额-售后退款</li>
        <li>【定制客运】实收结算金额=应收结算金额+营销优惠金额</li>
        <li>【汽车票】实收结算金额=预定金额-退订金额-退订手续费</li>
      </ol>
    </div>
    <!-- 操作栏 -->
    <div ref="ActionBar" class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">结算时间段：</div>
        <div class="col-2">
          <el-date-picker
            v-model="formData.month"
            value-format="yyyy-MM"
            type="month"
            placeholder="选择月"
          ></el-date-picker>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">业务类型：</div>
        <div class="col-2">
          <el-select
            v-model="formData.businessType"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in businessSelect"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">所属公司：</div>
        <div class="col-2">
          <companySelect
            :clearable="true"
            :company-tree="companyList"
            :valuename="valueAddName"
            :value="valueAdd"
            @getValue="getAddGroupId"
          ></companySelect>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">状态：</div>
        <div class="col-2">
          <el-select v-model="formData.status" clearable placeholder="请选择">
            <el-option
              v-for="item in statusSelect"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="actionBar-box">
        <el-button
          type="primary"
          size="small"
          @click="
            () => {
              this.formData.currentPage = 1;
              renderData();
            }
          "
          >查询</el-button
        >
        <el-button
          v-if="listFind('导出')"
          type="primary"
          size="small"
          @click="tableExport"
          >导出</el-button
        >
      </div>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :col-class-type="true"
      operation-width="150"
      :table-height="TableHeight"
    >
      <template slot-scope="scope">
        <div>
          <template v-if="listFind('查看pdf')">
            <el-button
              type="text"
              size="small"
              sort="primary"
              @click="tableDetailPopup(scope.scopeRow)"
              >查看</el-button
            >
          </template>
          <template v-if="listFind('确认')">
            <el-button
              v-show="scope.scopeRow.status == 0"
              type="text"
              size="small"
              sort="primary"
              @click="confirmFun(scope.scopeRow)"
              >确认</el-button
            >
          </template>
          <template v-if="listFind('结算')">
            <el-button
              v-show="scope.scopeRow.status == 1"
              type="text"
              size="small"
              sort="primary"
              @click="SettlementFun(scope.scopeRow)"
              >结算</el-button
            >
          </template>
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="formData.pageSize"
        :current-page="formData.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <TicketPopup
      v-if="ticketShow"
      :row-data-detail="rowDataDetail"
      :company="Company"
      :agent="agent"
      :agent2="agent2"
      @closeFun="
        () => {
          this.ticketShow = false;
        }
      "
    />
  </div>
</template>

<script>
import TicketPopup from "./ticketPopup/index.vue";
import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  statisticsAPI,
  getCompanyTree,
  statisticsConfirmAPI,
  statisticsSettlementAPI,
  statisticsExportAPI,
} from "@/api/lib/api.js";
export default {
  components: {
    TicketPopup,
    companySelect,
  },
  data() {
    return {
      agent: "",
      agent2: "",
      Company: "",
      tableData: [],
      rowDataDetail: undefined,
      titleName: [
        {
          title: "结算时间段",
          props: "month",
          width: "100",
        },
        {
          title: "生成时间",
          props: "createTime",
          width: 170,
        },
        {
          title: "公司",
          props: "companyName",
          width: 150,
        },
        {
          title: "业务类型",
          props: "businessType",
          SpecialJudgment: (res) => {
            return res == 1
              ? "城际专车"
              : res == 2
              ? "汽车票"
              : res == 4
              ? "包车"
              : res == 5
              ? "租车"
              : res == 6
              ? "定制班线"
              : "";
          },
        },
        {
          title: "预订数量",
          props: "saleTicketNum",
        },
        {
          title: "预订金额",
          props: "saleTicketAmount",
        },
        {
          title: "退订数量",
          props: "refundTicketNum",
        },
        {
          title: "退订金额",
          props: "refundTicketAmount",
        },
        {
          title: "退订手续费",
          props: "poundage",
          width: "100",
        },
        {
          title: "售后退款",
          props: "salesRefundAmount",
          SpecialJudgment: (res) => (res ? res : 0),
        },
        {
          title: "应收结算金额",
          props: "payablePrices",
          width: 120,
        },
        {
          title: "营销优惠金额",
          props: "preferentialPrices",
          width: 120,
        },
        {
          title: "实收结算金额",
          props: "settlementAmount",
          width: 120,
        },
        {
          title: "状态",
          props: "status",
          SpecialJudgment: (res) => {
            return res == 0
              ? "待确认"
              : res == 1
              ? "已确认"
              : res == 2
              ? "已结算"
              : "";
          },
        },
        {
          title: "确认人",
          props: "confirmUser",
        },
        {
          title: "确认时间",
          props: "confirmTime",
        },
        {
          title: "结算人",
          props: "settlementUser",
        },
        {
          title: "结算时间",
          props: "settlementTime",
          width: 170,
        },
      ],
      companyList: [],
      valueAddName: null,
      valueAdd: null,
      TableHeight: 0,
      ticketShow: false,
      businessSelect: [
        { value: 1, label: "城际专车" },
        { value: 2, label: "汽车票" },
        { value: 4, label: "包车" },
        { value: 5, label: "租车" },
        { value: 6, label: "定制班线" },
      ],
      statusSelect: [
        { value: 0, label: "待确认" },
        { value: 1, label: "已确认" },
        { value: 2, label: "已结算" },
      ],
      total: 0,
      formData: {
        month: "",
        businessType: "",
        status: "",
        companyIds: [],
        currentPage: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.getCompanyList();
    this.renderData();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
      console.log(this.TableHeight);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    // 点击导出按钮
    tableExport() {
      statisticsExportAPI({
        status: this.formData.status,
        businessType: this.formData.businessType,
        month: this.formData.month,
        companyIds: this.formData.companyIds,
      }).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "售票结算表 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    // 点击结算操作
    SettlementFun(scope) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [h("span", null, "确认结算？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          statisticsSettlementAPI({ id: scope.id }).then((res) => {
            if (res.code == "SUCCESS" && res.data == true) {
              this.renderData();
            }
          });
        })
        .catch(() => {});
    },
    // 点击确认按钮
    confirmFun(scope) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [h("span", null, "是否确认？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          statisticsConfirmAPI({ id: scope.id }).then((res) => {
            if (res.code == "SUCCESS" && res.data == true) {
              this.renderData();
            }
          });
        })
        .catch(() => {});
    },
    // 表格详情
    tableDetailPopup(scopeRow) {
      this.rowDataDetail = scopeRow;
      this.agent = scopeRow.confirmUser;
      this.agent2 = scopeRow.settlementUser;
      this.Company = scopeRow.companyName;
      this.ticketShow = true;
    },
    // 表格数据渲染
    renderData() {
      statisticsAPI(this.formData).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.formData.currentPage = page;
      this.renderData();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.formData.pageSize = size;
      this.formData.currentPage = 1;
      this.renderData();
    },
    // 企业回显
    getAddGroupId(v) {
      if (v == null) {
        this.formData.companyIds = [];
      } else {
        this.formData.companyIds = [v];
      }
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.ticketSettlement.clientHeight;
      this.TableHeight = wholeHeight - 300 + "px";
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-date-editor .el-range-separator {
  line-height: 25px;
}
.ticketSettlement {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  height: calc(100% - 32px);
  padding: 16px 20px;
  background: #ffffff;
  .ActionBar {
    background: #f7f8f9;
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 16px;
    flex-flow: wrap;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box:after {
      content: "";
      flex: auto;
    }
    .actionBar-box {
      width: calc(25% - 13px);
      height: 32px;
      line-height: 32px;
      margin-right: 14px;
      margin-bottom: 16px;
      display: flex;
      .col-1 {
        width: 84px;
        display: inline-block;
      }
      .col-2 {
        max-width: 210px;
        width: calc(100% - 84px);
        .el-select {
          width: 100%;
        }
      }
    }
  }
  .explain {
    color: #666666;
    font-size: 14px;
    background-color: #e4ecff;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    header {
      font-weight: bold;
      .el-icon-warning {
        color: #336ffe;
        font-size: 17px;
      }
    }
    ol {
      margin-bottom: 0;
      li {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
