var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "ticketSettlement", staticClass: "ticketSettlement" },
    [
      _c("div", { ref: "explain", staticClass: "explain" }, [
        _vm._m(0),
        _vm._m(1),
      ]),
      _c("div", { ref: "ActionBar", staticClass: "ActionBar" }, [
        _c("div", { staticClass: "actionBar-box" }, [
          _c("div", { staticClass: "col-1" }, [_vm._v("结算时间段：")]),
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM",
                  type: "month",
                  placeholder: "选择月",
                },
                model: {
                  value: _vm.formData.month,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "month", $$v)
                  },
                  expression: "formData.month",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "actionBar-box" }, [
          _c("div", { staticClass: "col-1" }, [_vm._v("业务类型：")]),
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.formData.businessType,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "businessType", $$v)
                    },
                    expression: "formData.businessType",
                  },
                },
                _vm._l(_vm.businessSelect, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "actionBar-box" }, [
          _c("div", { staticClass: "col-1" }, [_vm._v("所属公司：")]),
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c("companySelect", {
                attrs: {
                  clearable: true,
                  "company-tree": _vm.companyList,
                  valuename: _vm.valueAddName,
                  value: _vm.valueAdd,
                },
                on: { getValue: _vm.getAddGroupId },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "actionBar-box" }, [
          _c("div", { staticClass: "col-1" }, [_vm._v("状态：")]),
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.formData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status",
                  },
                },
                _vm._l(_vm.statusSelect, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "actionBar-box" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: {
                  click: () => {
                    this.formData.currentPage = 1
                    _vm.renderData()
                  },
                },
              },
              [_vm._v("查询")]
            ),
            _vm.listFind("导出")
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.tableExport },
                  },
                  [_vm._v("导出")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("Table", {
        attrs: {
          "table-data": _vm.tableData,
          "title-name": _vm.titleName,
          operation: true,
          "col-class-type": true,
          "operation-width": "150",
          "table-height": _vm.TableHeight,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  [
                    _vm.listFind("查看pdf")
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "text",
                                size: "small",
                                sort: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.tableDetailPopup(scope.scopeRow)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                        ]
                      : _vm._e(),
                    _vm.listFind("确认")
                      ? [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: scope.scopeRow.status == 0,
                                  expression: "scope.scopeRow.status == 0",
                                },
                              ],
                              attrs: {
                                type: "text",
                                size: "small",
                                sort: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmFun(scope.scopeRow)
                                },
                              },
                            },
                            [_vm._v("确认")]
                          ),
                        ]
                      : _vm._e(),
                    _vm.listFind("结算")
                      ? [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: scope.scopeRow.status == 1,
                                  expression: "scope.scopeRow.status == 1",
                                },
                              ],
                              attrs: {
                                type: "text",
                                size: "small",
                                sort: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.SettlementFun(scope.scopeRow)
                                },
                              },
                            },
                            [_vm._v("结算")]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.formData.pageSize,
              "current-page": _vm.formData.currentPage,
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm.ticketShow
        ? _c("TicketPopup", {
            attrs: {
              "row-data-detail": _vm.rowDataDetail,
              company: _vm.Company,
              agent: _vm.agent,
              agent2: _vm.agent2,
            },
            on: {
              closeFun: () => {
                this.ticketShow = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("i", { staticClass: "el-icon-warning" }),
      _vm._v(" 数据说明"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ol", [
      _c("li", [_vm._v("【定制客运】应收结算金额=预定金额-退订金额-售后退款")]),
      _c("li", [_vm._v("【定制客运】实收结算金额=应收结算金额+营销优惠金额")]),
      _c("li", [_vm._v("【汽车票】实收结算金额=预定金额-退订金额-退订手续费")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }